import { Box, Button, Input, Text } from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import { ApiContext, UserContext } from "../components/Root";

function InvitePage() {
  const user = useContext(UserContext);
  const api = useContext(ApiContext);
  const [inviteCode, setInviteCode] = useState("");

  return (
    <Box>
      <Text>Input your invite code</Text>
      <Input
        value={inviteCode}
        onChange={(e) => setInviteCode(e.target.value)}
      />
      <Button
        onClick={async () => {
          const redeemResponse = await api.client.redeemInvite({
            invite_code: inviteCode,
          });
          if (!redeemResponse) {
            console.log("error redeeming invite");
            return;
          }
          console.log(redeemResponse.influencer_id);
          user.setUser({
            ...user.data,
            is_influencer: true,
          });
        }}
      >
        Redeem Invite
      </Button>
    </Box>
  );
}

export default InvitePage;
